<template>
  <v-expansion-panel-text>
    <p class="text-h6 text-success">Fertig!</p>
    <p class="text-h6">
      Das ist der <span class="text-primary">erste</span> Eintrag. Sollte das
      nicht stimmen, dann ändere die
      <span class="text-primary">Konfiguration</span>:
    </p>
    <br />
    <v-table hover class="rounded-t-lg">
      <thead style="background-color: #464951">
        <tr>
          <th>ID</th>
          <th>Identifier</th>
          <th>Vorname</th>
          <th>Nachname</th>
          <th>Job</th>
          <th>Job Rang</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-for="(item, index) in result" :key="index">{{ item }}</td>
        </tr>
      </tbody>
    </v-table>
    <br />
    <div v-if="chars !== null">
      <p class="text-h6">
        Folgende <span class="text-primary">Spieler</span> wurden dem
        <span class="text-primary">Identifier</span> zugeordnet:
      </p>
      <br />
      <v-text-field
        v-model="Identifier"
        label="Identifier aus der Datenbank"
        append-icon="mdi-reload"
        @click:append="reload"
      ></v-text-field>
      <v-table hover class="rounded-t-lg">
        <thead style="background-color: #464951" >
          <tr>
            <th>ID</th>
            <th>Vorname</th>
            <th>Nachname</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(char, index) in chars" :key="index">
            <td>{{ char.id }}</td>
            <td>{{ char.firstname }}</td>
            <td>{{ char.lastname }}</td>
          </tr>
        </tbody>
      </v-table>
    </div>
  </v-expansion-panel-text>
</template>

<script>
export default {
  props: ["loading", "result", "chars", "ident"],
  data() {
    return {
      Identifier: null,
    };
  },
  watch: {
    ident(){
        this.Identifier = this.ident;
    }
  },
  methods: {
    reload(){
        this.$emit("reloadMulti", this.Identifier);
    }
  }
};
</script>
