import router from "../../Router.js";

export default {
  namespaced: true,
  state() {
    return {
      rights: null,
    };
  },
  getters:{
    hasRight: (state) => (right) =>{
        if (!state.rights && !sessionStorage.getItem("rights")) {
          sessionStorage.clear();
          router.push("/login");
          return
        } else if (!state.rights && sessionStorage.getItem("rights")) {
          state.rights = JSON.parse(sessionStorage.getItem("rights"));
        }
  
        if (Boolean(state.rights["admin"]) || Boolean(state.rights[right])) return true; 
        return false;
  
        
      },
  },
  mutations: {
    setRights(state, data) {
      state.rights = data;
      console.log(data)
      sessionStorage.setItem("rights", JSON.stringify(data));
    },

  },
};
