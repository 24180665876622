<template>
  <v-card color="surface" class="overflow-auto pb-10" :loading="loading">
    <v-card-title class="text-high-emphasis text-center">
      <v-row>
        <v-col cols="4"></v-col>
        <v-col cols="4" class="d-flex align-center justify-center">
          {{ title }}</v-col
        >
        <v-col cols="4" class="text-end">
          <v-btn flat icon="mdi-close" @click="$emit('close')"></v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider class="mb-5"></v-divider>
    <v-form @submit.prevent="newFrac" ref="form">
      <v-row align="center" justify="center">
        <v-col cols="8" class="ma-0 pb-4 pr-0 pl-0">
          <v-text-field
            v-model="name"
            label="Name der Fraktion"
            :rules="[rules.notEmpty]"
            placeholder="Polizei"></v-text-field>
        </v-col>
      </v-row>
      <v-row
        v-for="name in jobNames"
        :key="name"
        align="center"
        justify="center">
        <v-col cols="8" class="ma-0 pb-1 pr-0 pl-0">
          <v-text-field
            v-model="name.value"
            label="Jobname"
            :rules="[rules.notEmpty]"
            placeholder="police"
            hint="Bitte auch die Außer Dienst Namen eintragen (offpolice)"></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="1">
          <v-btn icon="mdi-plus" color="secondary" @click="addName"></v-btn>
        </v-col>
        <v-col cols="1" v-if="jobNames.length > 2">
          <v-btn icon="mdi-minus" color="secondary" @click="rmvName"></v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="2">
          <v-btn
            :loading="loading"
            color="primary"
            size="large"
            type="submit"
            variant="elevated">
            {{ buttonText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  props: ["updatedFrac"],
  data() {
    return {
      loading: false,
      title: "Neue Fraktion",
      name: null,
      jobNames: [{ value: "" }, { value: "" }],
      rules: {
        notEmpty: (value) => !!value || "Bitte ausfüllen.",
      },
      buttonText: "Speichern",
    };
  },
  methods: {
    addName() {
      this.jobNames.push({ value: "" });
    },
    rmvName() {
      this.jobNames.pop();
    },
    async newFrac() {
      const { valid } = await this.$refs["form"].validate();
      if (!valid) return;

      let jobNames = [];
      this.jobNames.forEach((n) => {
        jobNames.push(n.value);
      });

      let payload = {
        name: this.name,
        jobNames,
      };

      if(this.updatedFrac){
        payload._id = this.updatedFrac._id;
      }

      await axios({
        url: "/fracs/frac",
        method: this.updatedFrac ? "patch" : "post",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
        data: {
          ...payload,
        },
      })
        .then((res) => {
          if (this.updatedFrac) {
            payload._id = res.data.id;
            this.$emit(
              "finishUpdateFrac",
              { ...payload },
              this.updatedFrac._id
            );
            this.$store.dispatch("alerts/setAlerts", {
              type: "success",
              text: "Die Fraktion wurde erfolgreich bearbeitet",
            });
          } else {
            payload._id = res.data.id;
            this.$emit("createFrac", { ...payload });
            this.$store.dispatch("alerts/setAlerts", {
              type: "success",
              text: "Die Fraktion wurde erfolgreich erstellt",
            });
          }
        })
        .catch((err) => {
          this.$store.dispatch("alerts/errHandler", err);
        });
    },
  },
  created() {
    if (this.updatedFrac) {
      const frac = this.updatedFrac;
      this.title = frac.name + " bearbeiten";
      (this.name = frac.name), (this.jobNames = []);
      frac.jobNames.forEach((f) => this.jobNames.push({ value: f }));
    } else {
      this.title = "Neue Fraktion";
      this.name = null;
      this.jobNames = [{ value: "" }, { value: "" }];
    }
  },
};
</script>
