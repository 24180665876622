<template>
  <v-text-field
    v-model="search"
    prepend-inner-icon="mdi-magnify"
    label="Suche"
    single-line
    hide-details
    clearable>
  </v-text-field>
  <v-data-table
    :headers="headers"
    :items="players"
    :search="search"
    items-per-page="-1"
    fixed-header
    fixed-footer
    @click:row="click"
    height="50vh">
    <!-- <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        size="small"
        class="me-2 text-primary"
        @click="this.$router.push({ name: 'File', params: { id: item.raw.ooc_id } })">
        mdi-open-in-new
      </v-icon>
    </template> -->

    <template v-slot:bottom></template>
  </v-data-table>
</template>

<script>
export default {
  props: ["players"],
  data() {
    return {
      search: "",
      timer: null,
      headers: [
        { key: "Firstname", title: "Vorname" },
        { key: "Lastname", title: "Nachname" },
        { key: "Identifier", title: "Identifier" },
      ],
    };
  },
  methods:{
    click( _, index){
      this.$router.push({ name: 'File', params: { id: index.item.raw.ooc_ID } })  
    },
  },
};
</script>

<style>
td {
  text-align: start !important;
}

td:hover{
  cursor: pointer !important;
}
</style>
