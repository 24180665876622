<template>
  <v-expansion-panel-title :class="classObject" :expand-icon="icon">
    <v-row no-gutters>
      <v-col cols="4" class="d-flex justify-start"> {{ text }} </v-col>
      <v-col cols="8" class="text--secondary">
        <v-fade-transition leave-absolute>
          <v-row no-gutters style="width: 100%">
            <v-col cols="6" class="d-flex justify-center">
              {{ shortDesc }}
            </v-col>
            <v-col cols="6" class="d-flex justify-center"> Datum: {{ date }} </v-col>
          </v-row>
        </v-fade-transition>
      </v-col>
    </v-row>
  </v-expansion-panel-title>
</template>


<script>
  export default{
    props: ["type", "text", "shortDesc", "date", "isFinished"],
    computed: {
      classObject(){
        const type = this.type;
        return{
          'bg-bg-ban': type == 'ban',
          'bg-bg-aPoints': type == 'aPoints',
          'bg-bg-dPoints': type == 'dPoints',
          'bg-bg-info': type == 'info'
        }
      },
      icon(){
        if(this.isFinished) return "mdi-check";
        return "mdi-menu-down"
      }
    }
  }
</script>
