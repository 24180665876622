<template>
  <v-container style="position: relative">
    <the-card :loading="loading">
      <template v-slot:title>
        <v-card-title class="text-high-emphasis">
          Benutzer- und Rollenübersicht
        </v-card-title>
        <v-divider></v-divider>
      </template>
      <v-tabs fixed-tabs color="primary" v-model="tab">
        <v-tab value="user">Benutzer</v-tab>
        <v-tab value="role">Rollen</v-tab>
      </v-tabs>
      <v-window v-model="tab">
        <user-overview
          :loading="loading"
          :users="users"
          @newUser="userDialog = true; updateUser = null"
          @loading="loading = !loading"
          @deleteUser="deleteUser"
          @editUser="editUser"></user-overview>
        <role-overview
          :roles="roles"
          @openRoleDialog="openRoleDialog"
          @newRole="newRole"></role-overview>
      </v-window>
    </the-card>
    <v-dialog v-model="roleDialog">
      <role-dialog
        @close="roleDialog = false"
        :roles="roles"
        :updateRole="updatedRole"
        @finishNewRole="finishNewRole"
        @finishUpdate="finishUpdate"
        @deleteRole="deleteRole"></role-dialog>
    </v-dialog>
    <v-dialog v-model="userDialog">
      <user-dialog
        @close="userDialog = false"
        :roles="roles"
        :updateUser="updateUser"
        @newUser="newUser"
        @editUser="updateUserFunc"></user-dialog>
    </v-dialog>
  </v-container>
</template>

<script>
import TheCard from "@/components/Layouts/TheCard.vue";
import UserOverview from "@/components/UserAndRoles/UserOverview.vue";
import RoleOverview from "@/components/UserAndRoles/RoleOverview.vue";
import RoleDialog from "@/components/UserAndRoles/RoleDialog.vue";
import UserDialog from "@/components/UserAndRoles/UserDialog.vue";
import axios from "axios";
export default {
  components: {
    TheCard,
    UserOverview,
    RoleOverview,
    RoleDialog,
    UserDialog,
  },
  data() {
    return {
      tab: null,
      loading: false,
      users: [],
      roles: [],
      roleDialog: false,
      userDialog: false,
      updatedRole: null,
      updateUser: null,
    };
  },
  methods: {
    openRoleDialog: function (role) {
      this.updatedRole = role;
      this.roleDialog = true;
    },
    finishNewRole: function (newRole) {
      this.roles.push(newRole);
      this.roleDialog = false;
    },
    newRole() {
      this.updatedRole = null;
      this.roleDialog = true;
    },
    finishUpdate: function (newRole) {
      let index = this.roles.findIndex((rl) => rl.id == newRole.id);
      this.roles[index] = newRole;
      this.roleDialog = false;
    },
    deleteRole: function (id) {
      let index = this.roles.findIndex((rl) => rl.id == id);
      this.roles.splice(index, 1);
      this.roleDialog = false;
    },
    newUser: function (name, roleID, id) {
      const role = this.roles.find((rl) => rl.id == roleID);
      this.users.push({
        ID: id,
        Role_ID: roleID,
        Name: name,
        role_name: role.role_name,
      });
    },
    deleteUser: function(id){
      const index = this.users.findIndex(usr => usr.ID == id);
      console.table(this.users);
      console.log(index)
      console.log(id)
      this.users.splice(index, 1);
    },
    editUser: function(user){
      this.updateUser = user;
      this.userDialog = true;
    },
    updateUserFunc: function (name, roleID, id){
      const role = this.roles.find((rl) => rl.id == roleID);
      const index = this.users.findIndex(usr => usr.ID == id);
      this.users[index].Name = name;
      this.users[index].Role_ID = roleID;
      this.users[index].role_name = role.role_name;
      this.userDialog = false;
    },
  },
  async created() {
    this.loading = true;
    await axios({
      method: "get",
      url: "/user/init",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    }).then((res) => {
      this.loading = false;
      this.users = res.data.users;
      this.roles = res.data.roles;
    });
  },
};
</script>
