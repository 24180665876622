import { createApp } from 'vue'
import App from './App.vue'

import router from './Router';
import store from './store/index.js';
import './axios.js'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { VDataTable } from 'vuetify/labs/VDataTable'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'


import TheSupport from './components/Layouts/TheSupport.vue';

const myCustomDarkTheme = {
    dark: true,
    colors: {
      background: '#282C35',
      surface: '#282C35',
      light: '#a4e6f7',
      sidebar: '#21242B',
      primary: '#4091F9',
      'primary-darken-1': '#2c6977',
      secondary: '#03DAC6',
      'secondary-darken-1': '#018786',
      error: '#fe000b',
      info: '#2ea4c9',
      success: '#00b863',
      warning: '#ebcf00',
      accent1: '#00e5bb',
      accent2: '#238c7b',
      accent3: '#0dd2f2',
      'bg-ban': '#F74242',
      'bg-aPoints': '#FF9933',
      'bg-dPoints': '#00b863',
      'bg-info': '#4091F9',
    }
  }

const vuetify = createVuetify({
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        }
        },
    theme: {
        defaultTheme: 'myCustomDarkTheme',
        themes: {
          myCustomDarkTheme,
        }
    },
    components:{
      ...components,
      VDataTable
    },
  directives,

})

const app = createApp(App);

app.use(vuetify);
app.use(router);
app.use(store);
app.component("the-support", TheSupport);

app.mount('#app')
