<template>
  <v-sheet class="bg-background d-flex align-center" min-height="100vh" rounded>
    <v-card
      class="mx-auto px-6 py-8"
      elevation="5"
      style="width: 40vw"
      :loading="loading"
    >
      <v-form @submit.prevent="onSubmit">
        <v-text-field
          v-model="name"
          :rules="[rules.required, rules.nameFormat]"
          class="mb-2"
          clearable
          label="Benutzername"
          placeholder="xxx-Name"
          prepend-inner-icon="mdi-account"
          @keyup="changeWrongLoginData"
        ></v-text-field>

        <v-text-field
          v-model="password"
          :rules="[rules.required]"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          clearable
          label="Passwort"
          prepend-inner-icon="mdi-key"
          @click:append="showPassword = !showPassword"
          @keydown="changeWrongLoginData"
        ></v-text-field>

        <v-checkbox
          label="Benutzername speichern"
          v-model="saveName"
        ></v-checkbox>

        <p class="text-subtitle-1 text-error" v-if="wrongLoginData">
          Benutzername oder Passwort falsch
        </p>
        <p class="text-subtitle-1 text-success" v-if="isLoggedIn">
          Erfolgreich eingeloggt
        </p>

        <br />

        <v-btn
          :disabled="
            wrongLoginData ||
            password.length <= 0 ||
            name.length <= 0 ||
            !name.includes('-')
          "
          :loading="loading"
          color="primary"
          size="large"
          type="submit"
          variant="elevated"
        >
          Login
        </v-btn>
      </v-form>
    </v-card>
  </v-sheet>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      name: null,
      password: "",
      showPassword: "",
      saveName: false,
      wrongLoginData: false,
      isLoggedIn: false,
      rules: {
        required: (value) => !!value || "Bitte ausfüllen.",
        nameFormat: (value) =>
          value.includes("-") || "Bitte auf das Format Achten! Server-Name",
      },
    };
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      await axios({
        method: "post",
        url: "user/login",
        data: {
          name: this.name,
          password: this.password,
        },
      })
        .then(async (res) => {
          this.$store.dispatch("user/setAuth");
          
          this.isLoggedIn = true;

          await this.$store.commit("rights/setRights", res.data.msg.rights);
          sessionStorage.setItem("userName", this.name.slice(this.name.indexOf("-") + 1))

          sessionStorage.setItem("userToken", res.data.msg.token)

          

          if (this.saveName == true) {
            localStorage.setItem("Name", this.name);
          } else {
            localStorage.removeItem("Name");
          }
          console.log("log");
          this.$router.replace("/suche");
        })
        .catch((err) => {
          if (err.request.status == 401) {
            return (this.wrongLoginData = true);
          }

          this.$store.dispatch("alerts/errHandler", err);

        });

      this.loading = false;
    },
    changeWrongLoginData(e) {
      if (e.key != "Enter") {
        this.wrongLoginData = false;
      }
    },
  },
  created() {
    this.name = localStorage.getItem("Name");

    if (this.name != null) {
      this.saveName = true;
      this.form = true;
    }
  },
};
</script>
