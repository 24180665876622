<template>
  <v-expansion-panel>
    <v-expansion-panel-title>{{ title }}</v-expansion-panel-title>
    <v-expansion-panel-text>
      <ul class="mb-5 mt-5">
        <li
          v-for="p in frac.player"
          :key="p.ID"
          @click="
            this.$router.push({ name: 'File', params: { id: p.ooc_ID } })
          ">
          {{
            p.Firstname + " " + p.Lastname + ", " + p.Job + ", " + p.Job_grade
          }}
        </li>
        <v-btn
          v-if="$store.getters['rights/hasRight']('updateFrac')"
          color="secondary"
          type="submit"
          variant="elevated"
          @click="$emit('updateFrac', frac._id)"
          class="mt-5"
          size="small">
          Bearbeiten
        </v-btn>
        <v-btn
          v-if="$store.getters['rights/hasRight']('deleteFrac')"
          class="mt-5 ml-5"
          color="error"
          size="small"
          @click="deleteFrac"
          variant="elevated">
          Löschen
        </v-btn>
      </ul>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<script>
import axios from "axios";
export default {
  props: ["frac"],
  computed: {
    title() {
      return this.frac.name + ": " + this.frac.player.length + " Mitglieder";
    },
  },
  methods: {
    async deleteFrac() {
      if (
        !confirm(`Bist du sicher, dass du ${this.frac.name} löschen möchtest?`)
      )
        return;

      await axios({
        url: "/fracs/frac",
        method: "delete",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
        data: {
          id: this.frac._id,
        },
      })
        .then(() => {
          this.$emit("deleteFrac", this.frac._id);
          this.$store.dispatch("alerts/setAlerts", {
            type: "success",
            text: "Die Fraktion wurde erfolgreich gelöscht",
          });
        })
        .catch((err) => {
          this.$store.dispatch("alerts/errHandler", err);
        });
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  text-align: center;
  display: inline-block;
}

li:hover {
  cursor: pointer;
  background: #21242b;
  padding: 0 20px;
}
</style>
