<template>
  <v-expansion-panel-text>
    <v-form @submit.prevent="submit">
      <v-switch
        v-model="useMulti"
        label="Nutzt du ein Multichar System?"
        color="primary"
      ></v-switch>
      <span v-if="useMulti">
        <form>
          <p class="text-h5">
            Folgender <span class="text-primary">Identifier</span> wurde
            gefunden:
          </p>
          <br />
          <v-text-field
            v-model="ident"
            readonly
            label="Identifier in der Datenbank"
          ></v-text-field>
          <p class="text-subtitle-1">
            Wieviele Zeichen stehen vor dem eindeutigen Identifier?
            <v-tooltip
              location="top"
              text="Beispiel: 'char1:12345678977' => 6 Zeichen vor dem Indent."
            >
              <template v-slot:activator="{ props }">
                <v-icon
                  icon="mdi-information-outline"
                  color="primary"
                  v-bind="props"
                ></v-icon>
              </template>
            </v-tooltip>
          </p>
          <v-slider
            step="1"
            show-ticks="always"
            tick-size="4"
            :max="ident.length || 0"
            :min="0"
            thumb-label="always"
            v-model="endChar"
          ></v-slider>
          <v-text-field
            v-model="cutetIdent"
            readonly
            label="Identifier"
          ></v-text-field>
          <br />
        </form>
      </span>
      <v-btn type="submit" block class="mt-2" color="primary" :loading="loading"
        >Spiechern</v-btn
      >
    </v-form>
  </v-expansion-panel-text>
</template>

<script>
import axios from "axios";

export default {
  props: ["loading", "identifier", "data"],
  data() {
    return {
      useMulti: false,
      endChar: "",
    };
  },
  watch: {
    data() {
      if (this.data) {
        this.useMulti = this.data.use_multi === "true" ? true : false;
        this.endChar = this.data.cut_at;
      }
    },
  },
  computed: {
    ident() {
      return this.identifier;
    },
    cutetIdent() {
      return this.identifier.slice(this.endChar);
    },
  },
  methods: {
    async submit() {
      this.$emit("setLoading");
      await axios({
        method: "post",
        url: "/userServer/confiMultiChar",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
        data: {
          endChar: this.endChar,
          useMulti: this.useMulti,
        },
      })
        .then(() => {
          this.$emit("setLoading");
          sessionStorage.setItem("hasConfig", true);
          if(this.useMulti === true){
            this.$emit("reloadMulti", this.identifier);
          }
          this.$emit("changeStatus");
        })
        .catch((err) => {
          this.$store.dispatch("alerts/errHandler", err);

        });
    },
  },
};
</script>
