<template>
  <v-card color="surface" class="overflow-auto pb-10" :loading="loading">
    <v-card-title class="text-high-emphasis text-center">
      <v-row>
        <v-col cols="4"></v-col>
        <v-col cols="4" class="d-flex align-center justify-center">{{
          title
        }}</v-col>
        <v-col cols="4" class="text-end">
          <v-btn flat icon="mdi-close" @click="$emit('close')"></v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-form @submit.prevent="insertRole" ref="form">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-text-field
            v-model="name"
            label="Name der Rolle"
            :rules="[rules.notEmpty]"
            placeholder="Supporter"></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-select
            label="Soll eine bereits bestehende Rolle geklont werden?"
            clearable
            :disabled="updateRole != null"
            v-model="clone"
            :items="roles"
            item-title="role_name"
            item-value="id"></v-select>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row align="center" justify="center">
        <v-col cols="2" class="pb-0">
          <v-checkbox label="Administrator" v-model="admin"></v-checkbox>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="11" class="pt-0">
          <v-expansion-panels v-model="panel" :disabled="admin">
            <v-expansion-panel title="Supportmanagment Konfigurieren">
              <v-expansion-panel-text>
                <v-checkbox
                  label="Darf der Nutzer die Supportmanagment-Seite Konfigurieren?"
                  v-model="configServer"></v-checkbox>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel title="Benutzer verwalten">
              <v-expansion-panel-text>
                <v-checkbox
                  label="Darf der Nutzer sich die bestehenden Benutzer ansehen?"
                  v-model="seeUser"
                  hide-details></v-checkbox>
                <v-checkbox
                  label="Darf der Nutzer neue Benutzer anlegen?"
                  v-model="createUser"
                  hide-details
                  :disabled="seeUser != true"></v-checkbox>
                <v-checkbox
                  label="Darf der Nutzer Benutzer löschen?"
                  v-model="deleteUser"
                  hide-details
                  :disabled="seeUser != true">
                </v-checkbox>
                <v-checkbox
                  label="Darf der Nutzer andere Benutzer bearbeiten?"
                  v-model="editUser"
                  hide-details
                  :disabled="seeUser != true"></v-checkbox>
                <v-checkbox
                  label="Darf der Nutzer neue Passwörter für Benutzer generieren?"
                  v-model="editPW"
                  hide-details
                  :disabled="seeUser != true"></v-checkbox>
                <v-checkbox
                  label="Darf der Nutzer neue Rollen anlegen?"
                  v-model="createRole"
                  hide-details
                  :disabled="seeUser != true"></v-checkbox>
                <v-checkbox
                  label="Darf der Nutzer Rollen bearbeiten?"
                  v-model="editRole"
                  hide-details
                  :disabled="seeUser != true"></v-checkbox>
                <v-checkbox
                  label="Darf der Nutzer Rollen löschen?"
                  v-model="deleteRole"
                  hide-details
                  :disabled="seeUser != true"></v-checkbox>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel title="Einträge verwalten">
              <v-expansion-panel-text>
                <v-checkbox
                  label="Darf der Nutzer Einträge erstellen?"
                  v-model="createEntry"
                  hide-details></v-checkbox>
                <v-checkbox
                  label="Darf der Nutzer alle Einträge löschen?"
                  v-model="deleteAllEntrys"
                  hide-details
                  :disabled="!createEntry"></v-checkbox>
                <v-checkbox
                  label="Darf der Nutzer seine Einträge löschen?"
                  v-model="deleteOwnEntrys"
                  hide-details
                  :disabled="!createEntry || deleteAllEntrys"></v-checkbox>
                <v-checkbox
                  label="Darf der Nutzer alle Einträge bearbeiten?"
                  v-model="editAllEntrys"
                  hide-details
                  :disabled="!createEntry"></v-checkbox>
                <v-checkbox
                  label="Darf der Nutzer seine Einträge bearbeiten?"
                  v-model="editOwnEntrys"
                  hide-details
                  :disabled="!createEntry || editAllEntrys"></v-checkbox>
                <v-checkbox
                  label="Darf der Nutzer alle Einträge abschliessen?"
                  v-model="finishAllEntrys"
                  hide-details
                  :disabled="!createEntry"></v-checkbox>
                <v-checkbox
                  label="Darf der Nutzer seine Einträge abschliessen?"
                  v-model="finishOwnEntrys"
                  hide-details
                  :disabled="!createEntry || finishAllEntrys"></v-checkbox>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-btn
          v-if="$store.getters['rights/hasRight']('editRole')"
          :loading="loading"
          color="primary"
          size="large"
          type="submit"
          variant="elevated">
          {{ buttonText }}
        </v-btn>
        <v-btn
          v-if="updateRole && $store.getters['rights/hasRight']('deleteRole')"
          :loading="loading"
          color="secondary"
          size="large"
          @click="deleteRoleFunc"
          class="ml-5"
          variant="elevated">
          Löschen
        </v-btn>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  props: ["roles", "updateRole"],
  data() {
    return {
      name: null,
      clone: null,
      panel: [],
      buttonText: "Rolle Anlegen",
      title: "Neue Rolle",
      rules: {
        notEmpty: (value) => !!value || "Bitte ausfüllen.",
      },
      loading: false,

      admin: false,

      configServer: false,

      seeUser: false,
      createUser: false,
      deleteUser: false,
      editUser: false,
      editPW: false,
      createRole: false,
      editRole: false,
      deleteRole: false,

      createEntry: false,
      deleteAllEntrys: false,
      deleteOwnEntrys: false,
      editAllEntrys: false,
      editOwnEntrys: false,
      finishAllEntrys: false,
      finishOwnEntrys: false,
    };
  },
  methods: {
    async insertRole() {
      const { valid } = await this.$refs["form"].validate();
      if (!valid) return;

      this.loading = true;
      const old = this.updateRole;

      await axios({
        url: "/user/role",
        method: old ? "patch" : "post",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
        data: {
          name: this.name,
          admin: this.admin,
          configServer: this.configServer,
          seeUser: this.seeUser,
          createUser: this.createUser,
          editUser: this.editUser,
          createRole: this.createRole,
          editRole: this.editRole,
          editPW: this.editPW,
          createEntry: this.createEntry,
          deleteAllEntrys: this.deleteAllEntrys,
          deleteOwnEntrys: this.deleteOwnEntrys,
          editAllEntrys: this.editAllEntrys,
          editOwnEntrys: this.editOwnEntrys,
          finishAllEntrys: this.finishAllEntrys,
          finishOwnEntrys: this.finishOwnEntrys,
          deleteUser: this.deleteUser,
          deleteRole: this.deleteRole,
          id: old ? old.id : null,
        },
      })
        .then((res) => {
          this.loading = false;
          const newRole = {
            id: this.updateRole ? this.updateRole.id : res.data.id,
            role_name: this.name,
            admin: this.admin,
            configServer: this.configServer,
            seeUser: this.seeUser,
            createUser: this.createUser,
            editUser: this.editUser,
            createRole: this.createRole,
            editRole: this.editRole,
            editPW: this.editPW,
            createEntry: this.createEntry,
            deleteAllEntrys: this.deleteAllEntrys,
            deleteOwnEntrys: this.deleteOwnEntrys,
            editAllEntrys: this.editAllEntrys,
            editOwnEntrys: this.editOwnEntrys,
            finishAllEntrys: this.finishAllEntrys,
            finishOwnEntrys: this.finishOwnEntrys,
            deleteUser: this.deleteUser,
            deleteRole: this.deleteRole,
          };
          if (old) {
            this.$emit("finishUpdate", newRole);
            this.$store.dispatch("alerts/setAlerts", {
              type: "success",
              text: "Die Rolle wurde erfolgreich bearbeitet",
            });
          } else {
            this.$emit("finishNewRole", newRole);
            this.$store.dispatch("alerts/setAlerts", {
              type: "success",
              text: "Die Rolle wurde erfolgreich angelegt",
            });
          }

          this.setParamsNull();
        })
        .catch((err) => {
          this.loading = false;

          this.$store.dispatch("alerts/errHandler", err);
        });
    },
    async deleteRoleFunc() {
      if (!confirm("Bist du sicher, dass du die Rolle löschen willst?")) return;

      this.loading = true;
      await axios({
        url: "/user/role",
        method: "delete",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
        data: {
          id: this.updateRole.id,
        },
      })
        .then(() => {
          this.$emit("deleteRole", this.updateRole.id);
          this.$store.dispatch("alerts/setAlerts", {
            type: "success",
            text: "Die Rolle wurde erfolgreich gelöscht",
          });
        })
        .catch((err) => {
          this.loading = false;

          if (err.response.status == 400) {
            this.$store.dispatch("alerts/setAlerts", {
              type: "info",
              text: "Es gibt noch einen Nutzer mit dieser Rolle. Du musst dem Nutzer erst eine andere Rolle geben.",
            });
          } else {
            this.$store.dispatch("alerts/errHandler", err);
          }
        });
      this.loading = false;
    },
    setParams(role) {
      this.name = role.role_name;
      this.admin = !!role.admin;
      this.configServer = !!role.configServer;
      this.seeUser = !!role.seeUser;
      this.createUser = !!role.createUser;
      this.editUser = !!role.editUser;
      this.editPW = !!role.editPW;
      this.createRole = !!role.createRole;
      this.editRole = !!role.editRole;
      this.createEntry = !!role.createEntry;
      this.deleteAllEntrys = !!role.deleteAllEntrys;
      this.deleteOwnEntrys = !!role.deleteOwnEntrys;
      this.editAllEntrys = !!role.editAllEntrys;
      this.editOwnEntrys = !!role.editOwnEntrys;
      this.finishAllEntrys = !!role.finishAllEntrys;
      this.finishOwnEntrys = !!role.finishOwnEntrys;
      this.deleteUser = !!role.deleteUser;
      this.deleteRole = !!role.deleteRole;
    },
    setParamsNull() {
      this.name = "";
      this.admin = false;
      this.configServer = false;
      this.seeUser = false;
      this.createUser = false;
      this.editUser = false;
      this.editPW = false;
      this.createRole = false;
      this.editRole = false;
      this.createEntry = false;
      this.deleteAllEntrys = false;
      this.deleteOwnEntrys = false;
      this.editAllEntrys = false;
      this.editOwnEntrys = false;
      this.finishAllEntrys = false;
      this.finishOwnEntrys = false;
      this.deleteUser = false;
      this.deleteRole = false;
    },
  },
  watch: {
    clone() {
      if (this.clone) {
        let index = this.roles.findIndex((rl) => rl.id == this.clone);
        const role = this.roles[index];
        let name = this.name;
        this.setParams(role);
        this.name = name;
      } else {
        this.setParamsNull();
      }
    },
    admin(){
      this.panel = []
    }
  },
  created() {
    if (this.updateRole) {
      this.setParams(this.updateRole);
      this.buttonText = "Änderung Speichern";
      this.title = this.name + " bearbeiten";
    }
  },
};
</script>
