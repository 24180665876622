<template>
  <v-navigation-drawer v-if="showSidebar" rail class="bg-sidebar d-flex">
    <v-list density="compact" nav class="pt-8">
      <v-divider class="pb-1"></v-divider>

      <v-tooltip text="Spielersuche">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-home"
            title="Home"
            value="home"
            to="/suche"></v-list-item>
        </template>
      </v-tooltip>
      <v-tooltip text="Benutzer verwalten">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-if="$store.getters['rights/hasRight']('seeUser')"
            v-bind="props"
            prepend-icon="mdi-account-multiple"
            title="user"
            value="user"
            to="/user"></v-list-item>
        </template>
      </v-tooltip>
      <v-tooltip text="Fraktionen verwalten">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-if="$store.getters['rights/hasRight']('seeFracs')"
            v-bind="props"
            prepend-icon="mdi-office-building"
            title="fracs"
            value="fracs"
            to="/fracs"></v-list-item>
        </template>
      </v-tooltip>
      <v-tooltip text="Server Einstellung">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-if="$store.getters['rights/hasRight']('configServer')"
            v-bind="props"
            prepend-icon="mdi-cog"
            title="serverConfig"
            value="serverConfig"
            to="/server"></v-list-item>
        </template>
      </v-tooltip>
      <v-divider></v-divider>
    </v-list>

    <v-list
      density="compact"
      nav
      style="position: absolute; bottom: 0; width: 56px;">
      <v-tooltip text="Neues Passwort">
        <template v-slot:activator="{ props }">
          <v-list-item
            to="/newPW"
            v-bind="props"
            prepend-icon="mdi-key"
            value="newPW"
            ></v-list-item>
        </template>
      </v-tooltip>
      <v-tooltip text="Abmelden">
        <template v-slot:activator="{ props }">
          <v-list-item
            @click="logout"
            v-bind="props"
            prepend-icon="mdi-logout-variant"
            value="logout"
            class="text-primary"></v-list-item>
        </template>
      </v-tooltip>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    showSidebar() {
      if (this.$route.path == "/login") return false;

      return true;
    },
  },
  methods: {
    logout() {
      sessionStorage.clear();
      this.$router.go("/login");
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: var(--primary);
}
</style>
