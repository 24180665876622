<template>
  <v-window-item value="user">
    <v-container class="mx-auto">
      <v-btn
        v-if="$store.getters['rights/hasRight']('createUser')"
        :loading="loading"
        color="primary"
        type="submit"
        variant="elevated"
        @click="$emit('newUser')">
        Neuen Nutzer anlegen
      </v-btn>
      <v-text-field
        class="mt-5"
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        label="Suche"
        single-line
        hide-details
        clearable
        :loading="loading">
      </v-text-field>
      <v-data-table
        :headers="headers"
        :items="users"
        :search="search"
        items-per-page="-1"
        fixed-header
        fixed-footer>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="$store.getters['rights/hasRight']('deleteUser')"
            size="large"
            class="me-2 text-primary"
            @click="deleteUser(item.raw)"
            >mdi-delete
          </v-icon>
          <v-icon
            v-if="$store.getters['rights/hasRight']('editUser')"
            size="large"
            class="me-2 text-primary"
            @click="$emit('editUser', item.raw)"
            >mdi-account-edit
          </v-icon>
          <v-icon
            v-if="$store.getters['rights/hasRight']('editPW')"
            size="large"
            class="me-2 text-primary"
            @click="newPw(item.raw)"
            >mdi-key
          </v-icon>
        </template>
        <template v-slot:bottom></template>
      </v-data-table>
    </v-container>
  </v-window-item>
</template>

<script>
import axios from "axios";

export default {
  props: ["loading", "users"],
  data() {
    return {
      headers: [
        { title: "Funktionen", key: "actions", sortable: false },
        { title: "Name", key: "Name", align: "start" },
        { title: "Rolle", key: "role_name" },
      ],
      search: "",
    };
  },
  methods: {
    async deleteUser(user) {
      if (!confirm(`Bist du sicher, dass du ${user.Name} löschen möchtest?`))
        return;

      this.$emit("loading");

      await axios({
        url: "/user/user",
        method: "delete",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
        data: {
          id: user.ID,
        },
      })
        .then(() => {
          this.$store.dispatch("alerts/setAlerts", {
            type: "success",
            text: "Der Nutzer wurde erfolgreich gelöscht",
          });
          this.$emit("deleteUser", user.ID);
        })
        .catch((err) => {
          this.$store.dispatch("alerts/errHandler", err);
        });

      this.$emit("loading");
    },
    async newPw(user) {
      if (
        !confirm(
          `Bist du sicher, dass du ${user.Name} ein neues Passwort geben möchtest?`
        )
      )
        return;

      this.$emit("loading");

      await axios({
        url: "/user/pw",
        method: "patch",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
        data: {
          id: user.ID,
        },
      })
        .then((res) => {
          alert("Das neue Passwort lautet: " + res.data.msg);
        })
        .catch((err) => {
          this.$store.dispatch("alerts/errHandler", err);
        });

      this.$emit("loading");
    },
  },
};
</script>
