import { createStore } from 'vuex';

import userModule from './module/user.js';
import alertsModule from './module/Alerts.js';
import playerModule from './module/Player.js';
import rightsModule from './module/Rights.js';

const store = createStore({
    modules: {
        user: userModule,
        alerts: alertsModule,
        players: playerModule,
        rights: rightsModule,
    }
});

export default store;