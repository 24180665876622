<template>
  <VTabs fixed-tabs color="primary" v-model="tab">
    <VTab value="ooc" title="OOC-Übersicht" class="text-high-emphasis"></VTab>
    <VTab
      v-for="player in players"
      :title="player.Firstname + ' ' + player.Lastname"
      :key="player.PK"
      class="text-high-emphasis"
      :value="player.PK">
    </VTab>
  </VTabs>
  <VCardText>
    <VWindow v-model="tab">
      <VWindowItem value="ooc">
        <VContainer class="pa-0">
          <span class="d-flex flex-row align-center">
            <p class="text-h6 pr-11 text-high-emphasis">Discord:</p>
            <VTextField
              variant="underlined"
              class="text-high-emphasis"
              v-model="dc"
              :append-inner-icon="dc != ooc.dc ? 'mdi-content-save' : ''"
              @click:append-inner="updateDC"></VTextField>
          </span>
          <span class="d-flex flex-row align-center">
            <p class="text-h6 pr-9 text-high-emphasis">Identifier:</p>
            <VTextField
              variant="underlined"
              readonly
              class="text-high-emphasis"
              >{{ooc.ident}}</VTextField
            >
          </span>
          <span class="d-flex flex-row align-center">
            <p class="text-h6 pr-6 text-high-emphasis">Sonstiges:</p>
            <VTextarea
              clearable
              auto-grow
              rows="1"
              class="text-high-emphasis"
              v-model="other"
              :append-inner-icon="other != ooc.other ? 'mdi-content-save' : ''"
              @click:append-inner="updateOther"></VTextarea>
          </span>
        </VContainer>
      </VWindowItem>
      <PlayerView
        v-for="player in players"
        :job="player.Job"
        :jobGrade="player.Job_grade"
        :id="player.PK"
        :key="player.PK"></PlayerView>
    </VWindow>
  </VCardText>
</template>

<script>
import PlayerView from "../Player/PlayerView.vue";
import axios from "axios";

export default {
  components: {
    PlayerView,
  },
  props: ["players", "ooc", "entrys"],
  emits: ["setLoading", "updateDC", "updateOther"],
  data() {
    return {
      tab: null,
      dcName: null,
      dc: "",
      other: "",
    };
  },
  watch:{
    ooc(){
      this.dc = this.ooc.dc
      this.other = this.ooc.other
    }
  },
  methods:{
    async updateDC(){
      this.$emit('setLoading')

      await axios({
        url: "/player/dc",
        method: "patch",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
        data: {
          dc: this.dc,
          oocID: this.$route.params.id,
        },
      })
      .then(()=>{
        this.$emit('setLoading');
        this.$emit('updateDC', this.dc)
        this.$store.dispatch("alerts/setAlerts", {
            type: "success",
            text: "Die Änderung wurde gespeichert",
          });
      })
      .catch((err)=>{
        this.$emit('setLoading');
        this.$store.dispatch("alerts/errHandler", err);

      })
    },

    async updateOther(){
      this.$emit('setLoading')

      await axios({
        url: "/player/other",
        method: "patch",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
        data: {
          other: this.other,
          oocID: this.$route.params.id,
        },
      })
      .then(()=>{
        this.$emit('setLoading');
        this.$emit('updateOther', this.other)
        this.$store.dispatch("alerts/setAlerts", {
            type: "success",
            text: "Die Änderung wurde gespeichert",
          });
      })
      .catch((err)=>{
        this.$emit('setLoading');
        this.$store.dispatch("alerts/errHandler", err);

      })
    },
  },
};
</script>
