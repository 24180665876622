<template>
  <v-window-item :value="id">
    <v-container class="pa-0">
      <span class="d-flex flex-row align-center">
        <p class="text-h6 pr-11 text-high-emphasis">Job:</p>
        <v-text-field variant="underlined" class="text-high-emphasis" readonly>
          {{ job }}
        </v-text-field>
      </span>
      <span class="d-flex flex-row align-center">
        <p class="text-h6 pr-9 text-high-emphasis">Rang:</p>
        <v-text-field
          variant="underlined"
          readonly
          class="text-high-emphasis"
          >{{ jobGrade }}</v-text-field
        >
      </span>
    </v-container>
  </v-window-item>
</template>

<script>
export default {
  props: ["job", "jobGrade", "id"],
};
</script>
