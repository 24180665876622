<template>
  <v-sheet class="bg-background d-flex align-center" min-height="100vh" rounded>
    <v-card
      class="mx-auto px-6 py-8"
      elevation="5"
      style="width: 40vw"
      :loading="loading">
      <v-form @submit.prevent="onSubmit" ref="form">
        <v-text-field
          v-model="oldPW"
          :rules="[rules.notEmpty]"
          :error-messages="error"
          class="mb-2"
          clearable
          label="Altes Passwort"
          :append-inner-icon="showOldPW ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showOldPW ? 'text' : 'password'"
          @click:append-inner="showOldPW = !showOldPW"
          @keydown="error = ''"></v-text-field>
        <v-text-field
          :rules="[rules.notEmpty]"
          v-model="newPW"
          class="mb-2"
          clearable
          label="Neues Passwort"
          :append-inner-icon="showNewPW ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showNewPW ? 'text' : 'password'"
          @click:append-inner="showNewPW = !showNewPW"></v-text-field>
        <v-text-field
          :rules="[rules.notEmpty, rules.notSame]"
          v-model="newPW2"
          class="mb-2"
          clearable
          label="Neues Passwort Kontrolle"
          :append-inner-icon="showNewPW2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showNewPW2 ? 'text' : 'password'"
          @click:append-inner="showNewPW2 = !showNewPW2"></v-text-field>

        <v-btn
          :loading="loading"
          color="primary"
          size="large"
          type="submit"
          variant="elevated">
          Speichern
        </v-btn>
      </v-form>
    </v-card>
  </v-sheet>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      oldPW: null,
      showOldPW: false,
      newPW: null,
      showNewPW: false,
      newPW2: null,
      showNewPW2: false,
      rules: {
        notEmpty: (v) => !!v || "Das Feld darf nicht leer sein!",
        notSame: (v) =>
          v == this.newPW || "Die Passwörter stimmen nicht überein",
      },
      error: "",
    };
  },
  methods: {
    async onSubmit() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) return;

      this.loading = true;
      await axios({
        method: "patch",
        url: "user/newPW",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
        data: {
          oldPW: this.oldPW,
          newPW: this.newPW,
        },
      })
        .then((res) => {
          this.loading = false;
          if (res.data.success == -1) {
            this.error = "Das Passwort ist falsch!"
          }else{
            this.$store.dispatch("alerts/setAlerts", {
            type: "success",
            text: "Das Passwort wurde erfolgreich geändert",
          });
          this.oldPW = null;
          this.newPW = null;
          this.newPW2 = null;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$store.dispatch("alerts/errHandler", err);
        });
    },
  },
};
</script>
