<template>
  <v-expansion-panel-text>
    <v-form @submit.prevent="submit">
      <v-text-field
        v-model="table"
        label="Name der Tabelle für die User"
        :rules="rules"
        :error-messages="tableError"
      ></v-text-field>
      <v-text-field
        v-model="ID"
        label="Feldname für die ID"
        :rules="rules"
        :error-messages="IDError"
      ></v-text-field>
      
      <v-text-field
        v-model="identifier"
        label="Feldname für den Identifier (Lizenz, Steam ID o.ä.)"
        :rules="rules"
        :error-messages="identifierError"
      ></v-text-field>
      
      <v-text-field
        v-model="firstName"
        label="Feldname für den Vornamen"
        :rules="rules"
        :error-messages="firstNameError"
      ></v-text-field>
      
      <v-text-field
        v-model="lastName"
        label="Feldname für den Nachnamen"
        :rules="rules"
        :error-messages="lastNameError"
      ></v-text-field>
      
      <v-text-field
        v-model="job"
        label="Feldname für den Job"
        :rules="rules"
        :error-messages="jobError"
      ></v-text-field>
      
      <v-text-field
        v-model="jobGrade"
        label="Feldname für den Rang im Job"
        :rules="rules"
        :error-messages="jobGradeError"
      ></v-text-field>
      

      <h4 class="text-success" style="white-space: pre-line">{{ message }}</h4>
      <v-btn
        type="submit"
        block
        class="mt-2"
        color="primary"
        :disabled="table.length <= 0 || ID.length <= 0 || identifier.length <= 0 || firstName.length <= 0 || lastName.length <= 0 || job.length <= 0 || jobGrade.length <= 0"
        :loading="loading"
        >Speichern und Überprüfen</v-btn
      >
    </v-form>
  </v-expansion-panel-text>
</template>

<script>
import axios from 'axios';

export default {
  props: ["loading", "data"],
  data() {
    return {
      table: "",
      tableError: "",
      ID: "",
      IDError: "",
      identifier: "",
      identifierError: "",
      firstName: "",
      firstNameError: "",
      lastName: "",
      lastNameError: "",
      job: "",
      jobError: "",
      jobGrade: "",
      jobGradeError: "",
      message: "",
      rules: [
                value => {
                    if (value?.length == 0) return 'Bitte ausfüllen'
                    
                    return true
            }]
    };
  },
  watch: {
    data() {
      if (this.data) {
        this.table = this.data.table_name;
        this.ID = this.data.field_id;
        this.identifier = this.data.field_ident;
        this.firstName = this.data.field_firstname;
        this.lastName = this.data.field_lastname;
        this.job = this.data.field_job;
        this.jobGrade = this.data.field_job_grade;
      }
    },
  },
  methods:{
    async submit(){
      this.$emit("setLoading");
      this.tableError = "";
      this.IDError = "";
      this.identifierError = "";
      this.firstNameError = "";
      this.lastNameError = "";
      this.jobError = "";
      this.jobGradeError = "";
      this.message = "",

      await axios({
        method: "post",
        url: 'userServer/checkDataBase',
        headers: {'Authorization': 'Bearer '+ sessionStorage.getItem('userToken')},
        data:{
          table: this.table,
          ID: this.ID,
          identifier: this.identifier,
          firstName: this.firstName,
          lastName: this.lastName,
          job: this.job,
          jobGrade: this.jobGrade
        }      
      })
      .then((res) => {
        if(res.status === 200){
          this.message = "Die Tabelle kann ausgelesen werden! \n Du kannst jetzt zum nächsten Schritt";
          this.$emit("changeStatus");
          this.$emit("setLoading");
          this.$emit("setIdentifier", res.data.msg.identifier);
        }
      })
      .catch((err) => {
        const status = err.response.status;
        const data = err.response.data.msg;
        this.$emit("setLoading");
        
        if(status === 500 || status == 403){
          this.$store.dispatch("alerts/errHandler", err);
          return;
        }

        if(data.code == "ER_NO_SUCH_TABLE"){
          this.tableError = "Die Tabelle konnte nicht gefunden werden!";
          return;
        }
        
        const sqlMessage = data.sqlMessage
        const errorField = sqlMessage.substring(sqlMessage.indexOf("'") + 1, sqlMessage.lastIndexOf("'") - 16);
        switch (errorField) {
          case this.ID:
            this.IDError = "Das Feld konnte nicht gefunden werden";
            return;

          case this.identifier:
            this.identifierError = "Das Feld konnte nicht gefunden werden";
            return;

          case this.firstName:
            this.firstNameError = "Das Feld konnte nicht gefunden werden";
            return;
        
          case this.lastName:
            this.lastNameError = "Das Feld konnte nicht gefunden werden";
            return;

          case this.job:
            this.jobError = "Das Feld konnte nicht gefunden werden";
            return;
          
          case this.jobGrade:
            this.jobGradeError = "Das Feld konnte nicht gefunden werden";
            return;

        }

      })

    },
  }
}
</script>
