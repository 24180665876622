<template>
    <v-container class="d-flex justify-center bg-background" height="20vh">
       <small-card title="Chars" :text="length || 'Lädt'" :loading="loading"></small-card>
    </v-container>
</template>

<script>
import SmallCard from '../Cards/SmallCard.vue';
// import ButtonCard from '../Cards/ButtonCard.vue';

export default{
    components: {
        SmallCard,
        // ButtonCard
    },
    props: ["loading", "length"]
}
</script>