<template >
    <the-card :loading="loading" class="overflow-auto" style="max-height: 100vh;">
      <template v-slot:title>
        <v-card-title>Server Konfiguration</v-card-title>
        <v-divider></v-divider>
        <v-card-subtitle
          >Bei Fragen wende dich an den
          <the-support>Support</the-support></v-card-subtitle
        >
      </template>

      <template v-slot:>
        <v-expansion-panels v-model="panel">
          <v-expansion-panel>
            <panel-title
              :status="Station1"
              text="Mit Datenbank verbinden"
            ></panel-title>
            <UserInput
              @changeStatus="setStatus1"
              @setLoading="setLoading"
              :loading="loading"
              :data="data"
            ></UserInput>
          </v-expansion-panel>
          <v-expansion-panel :disabled="!Station1">
            <panel-title
              :status="Station2"
              text="Attribute vergeben"
            ></panel-title>
            <ConfigDB
              :loading="loading"
              :data="data"
              @setLoading="setLoading"
              @changeStatus="setStatus2"
              @setIdentifier="setIdentifier"
            ></ConfigDB>
          </v-expansion-panel>
          <v-expansion-panel :disabled="!Station2">
            <panel-title
              :status="Station3"
              text="MultiChar Konfigurieren"
            ></panel-title>
            <MultiChar
              :identifier="identifier"
              :data="data"
              @changeStatus="setStatus3"
              @setLoading="setLoading"
              @reloadMulti="testMulti"
            ></MultiChar>
          </v-expansion-panel>
          <v-expansion-panel :disabled="!Station2">
            <panel-title :status="Station4" text="Ergebnis"></panel-title>
            <ResultPanel
              :result="result"
              :chars="chars"
              :ident="identifier"
              @reloadMulti="testMulti"
            ></ResultPanel>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </the-card>
</template>

<script>
import TheCard from "@/components/Layouts/TheCard.vue";
import PanelTitle from "@/components/Server/PanelTitle.vue";
import UserInput from "@/components/Server/UserInput.vue";
import ConfigDB from "@/components/Server/ConfigDB.vue";
import MultiChar from "@/components/Server/MultiChar.vue";
import ResultPanel from "@/components/Server/ResultPanel.vue";
import axios from "axios";

export default {
  components: {
    TheCard,
    PanelTitle,
    UserInput,
    ConfigDB,
    MultiChar,
    ResultPanel,
  },
  data() {
    return {
      panel: [0],
      Station1: false,
      Station2: false,
      Station3: false,
      Station4: false,
      loading: false,
      data: null,
      result: null,
      identifier: null,
      chars: null,
    };
  },
  methods: {
    setLoading() {
      this.loading = !this.loading;
    },
    setIdentifier(ident) {
      this.identifier = ident;
    },
    setStatus1() {
      this.Station1 = true;
      this.panel = [1];
    },
    setStatus2() {
      this.Station2 = true;
      this.panel = [2];
    },
    setStatus3() {
      this.Station3 = true;
      this.Station4 = true;
      this.getResult();
      this.panel = [3];
    },
    async getIdent() {
      await axios({
        method: "get",
        url: "/userServer/getIdent",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      }).then((res) => {
        this.identifier = res.data.msg;
      });
    },
    async getResult() {
      this.setLoading();
      await axios({
        method: "get",
        url: "/userServer/getResult",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      })
        .then((res) => {
          this.result = res.data.msg;
        })
        .catch((err) => {
          this.$store.dispatch("alerts/errHandler", err);

        });
      this.setLoading();
    },
    async testMulti(ident) {
      this.setLoading();
      await axios({
        method: "post",
        url: "/userServer/checkMulti",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
        data: {
          ident: ident,
        },
      })
        .then((res) => {
          this.chars = res.data.msg;
        })
        .catch((err) => {
          this.$store.dispatch("alerts/errHandler", err);

        });
      this.setLoading();
    },
  },
  async created() {
    this.setLoading();
    await axios({
      method: "get",
      url: "/userServer/initialReq",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    })
      .then(async (res) => {
        const data = res.data.msg[0];
        console.log(data);
        this.data = data;
        if (data !== undefined) {
          if (
            data.url !== "" &&
            data.db_name !== "" &&
            data.user_name !== ""
          ) {
            this.setStatus1();
          }

          if (
            data.table_name !== "" &&
            data.field_id !== "" &&
            data.field_ident !== "" &&
            data.field_firstname !== "" &&
            data.field_lastname !== "" &&
            data.field_job !== "" &&
            data.field_job_grade !== ""
          ) {
            this.setStatus2();
            await this.getIdent();
          }

          if (data.use_multi !== null) {
            this.setStatus3();
            this.panel = []

            if (data.use_multi === "true") {
              this.testMulti(this.identifier);
            }
          }
        }
        this.setLoading();
      })
      .catch((err) => {
        this.setLoading();
        this.$store.dispatch("alerts/errHandler", err);

      });
  },
};
</script>
