<template>
  <small-card v-if="!playerInfos.isBanned" :title="playerInfos.points" text="Punkte" :loading="loading"></small-card>
  <small-card v-else :title="ban" text="Gebannt bis" :loading="loading" bg="bg-ban"></small-card>
</template>

<script>
import SmallCard from "../Cards/SmallCard.vue";
export default {
  components: {
    SmallCard,
  },
  props: ["loading", "playerInfos"],
  computed: {
    ban(){
        return new Date(this.playerInfos.bann.endDate).toLocaleDateString() + " " + this.playerInfos.bann.endTime; 
    }
  }
};
</script>
