<template>
  <v-container class="rounded-b-lg" style="background-color: #30333c">
    <v-row no-gutters>
      <v-col cols="4" class="d-flex justify-start align-center">
        Erstellt am {{createDate}}
      </v-col>
      <v-col cols="8" class="text--secondary">
        <v-fade-transition leave-absolute>
          <v-row no-gutters>
            <v-col cols="6" class="d-flex justify-center align-center">
              {{ creator }}
            </v-col>
            <v-col cols="6" class="d-flex justify-end">
              <v-btn
                v-if="!isFinished && showEdit"
                color="primary"
                type="submit"
                variant="tonal"
                class="mr-1"
                @click="update">
                Bearbeiten
              </v-btn>
              <v-btn
                v-if="showDelete"
                color="secondary"
                @click="deleteEntry"
                variant="tonal"
                :loading="loading">
                Löschen
              </v-btn>
              <v-btn
                v-if="!isFinished && showFinish"
                color="primary"
                @click="finishEntry"
                variant="tonal"
                :loading="loading">
                Abschließen
              </v-btn>
            </v-col>
          </v-row>
        </v-fade-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  props: ["createDate", "creator", "id", "isFinished", "type"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    showDelete() {
      if (this.$store.getters["rights/hasRight"]("deleteAllEntrys")) {
        return true;
      } else if (
        this.creator.toUpperCase() == sessionStorage.getItem("userName").toUpperCase() &&
        this.$store.getters["rights/hasRight"]("deleteOwnEntrys")
      ) {
        return true;
      }
      return false;
    },
    showEdit() {
      if (this.$store.getters["rights/hasRight"]("editAllEntrys")) {
        return true;
      } else if (
        this.creator.toUpperCase() == sessionStorage.getItem("userName").toUpperCase() &&
        this.$store.getters["rights/hasRight"]("editOwnEntrys")
      ) {
        return true;
      }
      return false;
    },
    showFinish() {
      if (this.$store.getters["rights/hasRight"]("finishAllEntrys") && this.type != "info") {
        return true;
      } else if (
        this.creator.toUpperCase() == sessionStorage.getItem("userName").toUpperCase() &&
        this.$store.getters["rights/hasRight"]("finishOwnEntrys")
        && this.type != "info"
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    update() {
      this.$emit("updateEntry");
    },
    async deleteEntry() {
      if (!confirm("Bist du sicher, dass du den Eintrag löschen willst?"))
        return;

      this.loading = true;
      await axios({
        url: "/player/entry",
        method: "delete",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
        data: {
          id: this.id,
          oocID: this.$route.params.id,
        },
      })
        .then((res) => {
          this.loading = false;
          this.$emit("updateEntrys", res.data.player);
          this.$store.dispatch("alerts/setAlerts", {
            type: "success",
            text: "Der Eintrag wurde erfolgreich gelöscht",
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$store.dispatch("alerts/errHandler", err);
        });
    },
    async finishEntry() {
      if (!confirm("Bist du sicher, dass du den Eintrag abschliessen willst?"))
        return;

      this.loading = true;
      await axios({
        url: "/player/finishEntry",
        method: "patch",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
        data: {
          id: this.id,
          oocID: this.$route.params.id,
        },
      })
        .then((res) => {
          this.loading = false;
          this.$emit("updateEntrys", res.data.player);
          this.$store.dispatch("alerts/setAlerts", {
            type: "success",
            text: "Der Eintrag ist nun abgeschlossen",
          });
        })
        .catch((err) => {
          this.loading = false;

          this.$store.dispatch("alerts/errHandler", err);
        });
    },
  },
};
</script>
