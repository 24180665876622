<template>
  <v-container  class="overflow-auto" style="max-height: 100vh">
    <v-container style="position: relative">
      <v-container class="d-flex justify-center bg-background">
        <player-overview-card :loading="loading" :playerInfos="playerInfos"></player-overview-card>
      </v-container>
      <the-card :loading="loading">
        <the-tabs :players="player" :ooc="ooc" @setLoading="loading = !loading" @updateDC="updateDC" @updateOther="updateOther"></the-tabs>
      </the-card>
      <the-card class="overflow-auto" style="max-height: 100vh">
        <template v-slot:title>
          <v-card-title class="text-high-emphasis">
            <v-row>
              <v-col cols="4"></v-col>
              <v-col cols="4">Einträge</v-col>
              <v-col cols="4" class="text-end">
                <v-btn
                v-if="$store.getters['rights/hasRight']('createEntry')"
                  color="primary"
                  type="submit"
                  variant="tonal"
                  class="mr-1">
                  Neuer Eintrag
                  <v-dialog
                    v-model="openNewEntryDialog"
                    activator="parent"
                    persistent>
                    <add-entry-dialog
                      @close="closeNewEntryDialog"
                      @updateEntrys="updateEntrys"
                      :updatedEntry="updatedEntry"
                      ></add-entry-dialog>
                  </v-dialog>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-subtitle class="pb-2" v-if="entrys.length > 0"
            >Bei Fragen wende dich an den
            <the-support>Support</the-support>
          </v-card-subtitle>
        </template>
        <player-entrys
          :entrys="entrys"
          @updateEntrys="updateEntrys"
          @updateEntry="updateEntry"></player-entrys>
      </the-card>
    </v-container>
  </v-container>
</template>

<script>
import TheCard from "@/components/Layouts/TheCard.vue";
import PlayerOverviewCard from "@/components/Player/PlayerOverviewCard.vue";
import TheTabs from "@/components/Layouts/TheTabs.vue";
import PlayerEntrys from "@/components/Player/PlayerEntrys.vue";
import AddEntryDialog from "@/components/Player/AddEntryDialog.vue";

import axios from "axios";

export default {
  components: {
    TheCard,
    PlayerOverviewCard,
    TheTabs,
    PlayerEntrys,
    AddEntryDialog,
  },
  data() {
    return {
      points: 8,
      ooc: {},
      player: {},
      playerInfos: {},
      entrys: [],
      loading: false,
      openNewEntryDialog: false,
      updatedEntry: null,
    };
  },
  methods: {
    async getPlayer() {
      this.loading = true;
      await axios({
        method: "get",
        url: "/player/getPlayer",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
        params: {
          oocID: this.$route.params.id,
        },
      })
        .then((res) => {
          this.loading = false;
          this.player = res.data.msg.players;
          this.ooc = res.data.msg.ooc;
          this.entrys = res.data.msg.entrys;
          this.playerInfos = res.data.msg.playerInfos;
        })
        .catch((err) => {
          this.$store.dispatch("alerts/errHandler", err);

        });
    },
    closeNewEntryDialog() {
      this.openNewEntryDialog = false;
      this.updatedEntry = null;
    },
    updateEntrys: function (data) {
      this.entrys = data.entrys;
      this.playerInfos = {
        points: data.points || 0,
        isBanned: data.isBanned || false,
        bann: data.bann || {}
      }
      
    },
    updateEntry: function (entry) {
      this.updatedEntry = entry;
      this.openNewEntryDialog = true;
    },

    updateDC: function (dc) {
      this.ooc.dc = dc;
    },

    updateOther: function (other) {
      this.ooc.other = other;
    },
  },
  created() {
    this.getPlayer();
  },
};

// {{ $route.params.id }}
</script>
