<template>
  <v-expansion-panel-text>
    <h2>
      Bitte lege in der SQL-Datenbank einen Benutzer mit
      <span class="text-primary">lesenden</span> Rechten an!
    </h2>
    <br />
    <v-form @submit.prevent="checkConnection">
      <v-text-field
        v-model="url"
        label="Datenbankadresse (URL)"
        :rules="rules"
        :error-messages="urlError"
      ></v-text-field>
      <br />
      <v-text-field
        v-model="database"
        label="Datenbank"
        :rules="rules"
        :error-messages="databaseError"
      ></v-text-field>
      <br />
      <v-text-field
        v-model="userName"
        label="Benutzername"
        :rules="rules"
        :error-messages="userNameError"
      ></v-text-field>
      <br />
      <v-text-field
        v-model="pw"
        label="Passwort"
        :error-messages="pwError"
      ></v-text-field>

      <h4 class="text-success" style="white-space: pre-line">{{ message }}</h4>
      <v-btn
        type="submit"
        block
        class="mt-2"
        color="primary"
        :disabled="
          userName.length <= 0 || url.length <= 0 || database.length <= 0
        "
        :loading="loading"
        >Speichern und Überprüfen</v-btn
      >
    </v-form>
  </v-expansion-panel-text>
</template>

<script>
import axios from "axios";

export default {
  props: ["loading", "data"],
  data() {
    return {
      userName: "",
      userNameError: "",
      pw: "",
      pwError: "",
      url: "",
      urlError: "",
      database: "",
      databaseError: "",
      message: "",
      rules: [
        (value) => {
          if (value?.length == 0) return "Bitte ausfüllen";
          return true;
        },
      ],
    };
  },
  watch: {
    data() {
      if (this.data) {
        this.userName = this.data.user_name;
        this.pw = this.data.password;
        this.url = this.data.url;
        this.database = this.data.db_name;
      }
    },
  },
  methods: {
    async checkConnection() {
      this.$emit("setLoading");
      this.databaseError = "";
      this.userNameError = "";
      this.pwError = "";
      this.urlError = "";
      this.message = "";

      await axios({
        method: "post",
        url: "userServer/createConnection",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
        data: {
          host: this.url,
          userName: this.userName,
          password: this.pw,
          database: this.database,
          table: this.table,
        },
      })
        .then((res) => {
          console.log(res);

          if (res.status == 200) {
            this.message =
              "Die Verbindung wurde hergestellt! \n Du kannst jetzt zum nächsten Schritt";
            this.$emit("setLoading");
            this.$emit("changeStatus");
          }
        })
        .catch((err) => {
          const status = err.response.status;
          const data = err.response.data;

          this.$emit("setLoading");
          if (status == 510) {
            switch (data.msg.code) {
              case "ER_BAD_DB_ERROR":
                this.databaseError =
                  "Die Datenbank konnte nicht gefunden werden";
                return;

              case "ER_ACCESS_DENIED_ERROR":
                this.userNameError = "Benutzername oder Passwort falsch";
                this.pwError = "Benutzername oder Passwort falsch";
                return;

              case "ENOTFOUND":
                this.urlError = "Der Host konnte nicht gefunden werden";
                return;

              default:
                break;
            }
          }

          this.$store.dispatch("alerts/errHandler", err);

        });
    },
  },
};
</script>
