<template>
  <v-container>
      <v-slide-y-transition
        class="py-0"
        group
        tag="v-alert"
        style="background-color:"
      >
        <v-alert
        v-for="(alert) in alerts"
          v-model="alert.visible"
          closable
          :type="alert.type"
          :text="alert.text"
          :key="alert.id"
          density="compact"
          variant="elevated"
          :border="true"
        >
      </v-alert>
        </v-slide-y-transition>
  </v-container>

  <!-- <v-snackbar v-model="snackbar" v-for="alert in alerts" :key="alert.id" timeout="99999999" closable @click="addAlert"> -->
        <!-- <v-alert :type="alert.type" :text="alert.text" :key="alert.id" closable position="relative" ></v-alert> -->
    <!-- </v-snackbar> -->
</template>

<script>
export default {
  data() {
    return {
      snackbar: true,
    };
  },
  computed: {
    alerts() {
      return this.$store.getters["alerts/getAlerts"];
    },
  },
  methods:{
    addAlert(){
      this.$store.dispatch("alerts/setAlerts", {
            type: "success",
            text: "Der User wurde erfolgreich erstellt",
          });
    },
  }
};
</script>

<style scoped>
/* li {
  list-style: none;
}

.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
/* .fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
} */

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
/* .fade-leave-active {
  position: absolute;
  transition: all 1s;
} */ 

.v-alert{
  margin-top: 10px !important; 
  z-index:99 !important; 
  width: 50vh !important;
}
.v-alert:first-of-type{
  margin-top: 0px !important; 
}
.v-container{
  display: flex;
  justify-content: right;
  position: absolute;
}
</style>
