<template>
  <v-window-item value="role" class="mx-auto pt-5">
    <v-btn
    v-if="$store.getters['rights/hasRight']('createRole')"
      color="primary"
      type="submit"
      variant="elevated"
      @click="$emit('newRole')">
      Neue Rolle anlegen
    </v-btn>
    <v-divider class="mt-5"></v-divider>
    <v-list>
      <v-list-item
        v-for="role in roles"
        :title="role.role_name"
        :key="role.id"
        :value="role.id"
        class="text-medium-emphasis"
        @click="openDialog(role)">
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <h4 class="text-medium-emphasis">
      Zum Bearbeiten der Rollen, die Rolle anklicken
    </h4>
  </v-window-item>
</template>

<script>
export default {
  props: ["roles"],
  methods: {
    openDialog(role) {
      this.$emit("openRoleDialog", role);
    },
  },
};
</script>


