import axios from "axios";
import store from "../index.js";
import router from '../../Router.js';


export default {
  namespaced: true,
  state() {
    return {
      players: null,
      points: null,
      banns: null,
      loading: false,
      playerLength: null,
    };
  },
  getters: {
    getPlayers(state) {
      return state.players;
    },
    getPoints(state) {
      return state.points;
    },
    getBanns(state) {
      return state.banns;
    },
    isLoading(state) {
      return state.loading;
    },
    getPlayersLength(state) {
      return state.playerLength;
    },
  },
  mutations: {
    setPlayer(state, player){
      console.log("SET PLAYER", player);
      state.players = player;
    },

    init() {
      store.commit("players/reload");
    },

    async reload(state) {
      state.loading = true;
      await axios({
        method: "get",
        url: "player/init",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken"),
          'Cache-Control': 'public, max-age=300',
        },
      })
        .then((res) => {

          if(res.status == 204){
            router.push("/server");
            store.commit("alerts/setAlerts", {
              type: "info",
              text: "Bitte Konfiguriere den Server. Bei Fragen wende dich an den Support. Viel Erfolg!",
            });
            return;
          }
          state.players = res.data.msg;
          state.playerLength = state.players.length;
          state.loading = false;
        })
        .catch((err) => {
          store.dispatch("alerts/errHandler", err);
          console.error(err)
          state.loading = false;
        });
    },
  },
};
