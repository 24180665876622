<template>
  <v-footer
    border
    absolute
    style="
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 10vh;
    ">
    <v-row justify="center" no-gutters>
      <a
        href="https://discord.gg/yAZuYjSPjv"
        target="_blank"
        class="text-primary">
        <v-btn color="white" variant="text" class="mx-2" rounded="xl">
          Discord
        </v-btn>
      </a>
      <a href="" class="text-primary">
        <v-btn color="white" variant="text" class="mx-2" rounded="xl">
          Shop
        </v-btn>
      </a>
      <a href="" class="text-primary">
        <v-btn color="white" variant="text" class="mx-2" rounded="xl">
          AGB
        </v-btn>
      </a>
      <a href="" class="text-primary">
        <v-btn color="white" variant="text" class="mx-2" rounded="xl">
          Impressum
        </v-btn>
      </a>

      <v-col class="text-center mt-4" cols="12">
        {{ new Date().getFullYear() }} — <strong>Supportmanagment</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<style>
v-footer {
  position: fixed !important;
  left: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  background-color: red !important;
}
</style>
