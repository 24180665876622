<template>
  <v-expansion-panels v-if="entrys.length > 0" v-model="open">
    <the-entry v-for="entry in entrys.slice().reverse()" :entry="entry" :key="entry.id" @updateEntrys="updateEntrys" @updateEntry="updateEntry"></the-entry>
  </v-expansion-panels>
  <span v-else>
    <br />
    <h3 class="text-medium-emphasis">Hmm, anscheinend benimmt er sich...</h3>
  </span>
</template>

<script>
import TheEntry from "./PlayerEntry.vue";

export default {
  components: {
    TheEntry,
  },
  props: ["entrys"],
  data(){
    return{
      open: [],
    }
  },
  methods: {
    updateEntrys: function(data){
      this.open = []
      this.$emit("updateEntrys", data);
    },
    updateEntry: function(data){
      this.$emit("updateEntry", data);
    }
  },
};
</script>
