import { createRouter, createWebHistory } from 'vue-router';

import MainPage from './pages/MainPage.vue';
import LoginPage from './pages/LoginPage.vue';
import FilePage from './pages/FilePage.vue';
import ServerPage from './pages/ServerPage.vue';
import UserPage from './pages/UserPage.vue';
import NewPW from './pages/NewPW.vue';
import FracPage from './pages/FracPage.vue'

import Testpage from './pages/TestPage.vue';

import store from './store/index.js';

const router = createRouter({
    history: createWebHistory(),
    routes:[
        {path:'/', redirect: '/suche', meta: { requiresAuth: true}},
        {name: 'Login', path: '/login', component: LoginPage, meta: { requiresAuth: false}},
        {name: 'Home', path:'/suche', component: MainPage, meta: { requiresAuth: true}},
        {name: 'File', path:'/player/:id', component: FilePage, meta: { requiresAuth: true}},
        {name: 'Server', path:'/server', component: ServerPage, meta: { requiresAuth: true}},
        {name: 'User', path:'/user', component: UserPage, meta: { requiresAuth: true}},
        {name: 'NewPW', path:'/newPW', component: NewPW, meta: { requiresAuth: true}},
        {name: 'Fracs', path:'/fracs', component: FracPage, meta: { requiresAuth: true}},
        {name: 'test', path:'/test', component: Testpage, meta: { requiresAuth: true}},
    ]
});



router.beforeEach((to, _, next) => {
    let isAuth = false;
    
    if(sessionStorage.getItem("userName") && sessionStorage.getItem("userToken")) isAuth = true;

    console.log(isAuth)

    if(to.meta.requiresAuth && !isAuth ){
        next('login')
        return
        
    }else if(!to.meta.requiresAuth && isAuth){
        next('suche');
        return
    }else{
        if(to.name == "User" && !store.getters['rights/hasRight']('seeUser')){
            next('suche')
            return
        }else if(to.name == "Server" && !store.getters['rights/hasRight']('configServer')){
            next('suche')
            return
        }
        next()
    }

})


export default router;