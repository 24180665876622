<template>
    <v-card 
    width="20vw" 
    class="text-center pa-2 rounded-xl" 
    :color="bg" 
    elevation="5"
    :loading="loading"
    >
        <slot name="title"><v-card-title :class="titleClass">{{text}}</v-card-title></slot>
        <v-divider></v-divider>
        <slot name="text"><v-card-text  :class="textClass" >{{title}}</v-card-text></slot>
    </v-card> 
</template>


<script>

export default{
    props: {title:{}, text:{}, loading:{}, bg: {
        type: String,
        default: "surface"
    }},
    computed:{
        titleClass(){
            if(this.bg != "surface") return{
                'text-h5': true,
                'text-black': true,
            };
            return{
                'text-h5': true,
                'text-primary': true,
            }
        },
        textClass(){
            if(this.bg != "surface") return{
                'text-h5': true,
                'text-black': true,
            };
            return{
                'text-h5': true,
                'text-high-emphasis': true,
            }
        }
    }

}

</script>