<template>
  <h1>Test Seite</h1>
  <button type="button" @click="newErr" style="position: absolute; bottom: 0">
    new Error
  </button>
</template>

<script>
export default {
  methods: {
    newErr() {
      this.$store.dispatch("alerts/setAlerts", { type: "error", text: "Test" });
    },
  },
};
</script>
