<template>
  <v-expansion-panel class="pb-1" :style="opacity">
    <PlayerEntryHeader :type="entry.type" :text="text" :shortDesc="entry.shortDesc" :date="new Date(entry.date).toLocaleDateString()" :isFinished="entry.isFinished"></PlayerEntryHeader>
    <v-expansion-panel-text>
      <v-container>
        <p v-if="entry.type == 'ban'" class="text-h6 pr-11 text-medium-emphasis">
          Gebannt bis {{ new Date(entry.ban.endDate).toLocaleDateString() }} {{ entry.ban.endTime }}
        </p>
        <p v-if="entry.updated" class="text-h6 pr-11 text-medium-emphasis">Bearbeitet von {{ entry.updated.updater }} am {{ new Date(entry.updated.date).toLocaleDateString() }}</p>
        <br />
        <v-textarea
          readonly
          auto-grow
          rows="1"
          :model-value="entry.desc">
        </v-textarea>
      </v-container>
      <PlayerEntryFooter :type="entry.type" :createDate="new Date(entry.createDate).toLocaleDateString()" :creator="entry.creator" :id="entry._id" :isFinished="entry.isFinished" @updateEntrys="updateEntrys" @updateEntry="updateEntry"></PlayerEntryFooter>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<script>
import PlayerEntryHeader from "./PlayerEntryHeader.vue";
import PlayerEntryFooter from "./PlayerEntryFooter.vue";

export default {
  components: {
    PlayerEntryHeader,
    PlayerEntryFooter
  },
  props: ["entry"],
  methods: {
    updateEntrys: function(data){
      this.$emit("updateEntrys", data);
    },
    updateEntry(){
      this.$emit('updateEntry', this.entry)
    }
  },
  computed: {
    opacity(){
      if(this.entry.isFinished == true) return "opacity: 0.5"
      return "opacity: 1"
    },
    text(){
      let text
      switch (this.entry.type) {
        case 'ban':
          text =  "Bann";
          break;

        case 'aPoints':
          if(this.entry.points > 1){
            text =  "+" + this.entry.points + " Punkte";
          }else{
            text =  "+" + this.entry.points + " Punkt";
          }
          break;
        
        case 'dPoints':
          if(this.entry.points > 1){
            text =  "-" + this.entry.points + " Punkte";
          }else{
            text =  "-" + this.entry.points + " Punkt";
          }
          break
        
        case 'info':
          text = "Information";
          break;

        default:
          text = null
          break;
      }

      return text

    }
  }
};
</script>


<style>
.v-expansion-panel-text__wrapper{
  padding: 0px !important;
}
</style>
