<template>
  <v-app >
    <v-card >
        <the-sidebar></the-sidebar>
        <v-main  class="overflow-auto bg-background" style="height: 90vh;" >
          <the-alerts></the-alerts>
          <router-view></router-view>
        </v-main>
      <the-footer></the-footer>
    </v-card>
  </v-app>
</template>

<script>
import TheSidebar from './components/Layouts/TheSidebar.vue';
import TheAlerts from './components/Layouts/TheAlerts.vue';
import TheFooter from './components/Layouts/TheFooter.vue';

export default{
  components:{
    TheSidebar,
    TheAlerts,
    TheFooter,
  }
}
</script>

<style >
html { 
  overflow: none !important;
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
} 
html::-webkit-scrollbar {
  display: none !important;
}
</style>
