<template>
  <v-sheet class="bg-background d-flex align-center" min-height="100vh" rounded >
    <the-card :loading="loading">
        <template v-slot:title>
        <v-card-title class="text-high-emphasis">
          Fraktionsübersicht
        </v-card-title>
        <v-divider></v-divider>
      </template>
        <v-btn
        v-if="$store.getters['rights/hasRight']('createFrac')"
        :loading="loading"
        color="primary"
        type="submit"
        variant="elevated"
        @click="()=>{
          updatedFrac = false;
          dialog = true;
        }"
        class="mt-5">
        Neue Fraktion anlegen
      </v-btn>
      <v-expansion-panels v-model="panel" class="mt-5">
        <frac-panel v-for="(frac, index) in playerInFrac" :key="index" :frac="frac" @updateFrac="updateFrac" @deleteFrac="deleteFrac"></frac-panel>
      </v-expansion-panels>
    </the-card>
    <v-dialog v-model="dialog">
    <frac-dialog
    :updatedFrac="updatedFrac"
    @close="closeDialog"
    @createFrac="fracCreated"
    @finishUpdateFrac="finishUpdateFrac"
    ></frac-dialog>
  </v-dialog>
  </v-sheet>
</template>

<script>
import TheCard from "@/components/Layouts/TheCard.vue";
import FracPanel from "@/components/Fractions/FracPanel.vue"
import FracDialog from "@/components/Fractions/FracDialog.vue";
import axios from "axios";
export default {
  components: {
    TheCard,
    FracPanel,
    FracDialog,
  },
  data() {
    return {
      loading: false,
      player: [],
      fracs: [],
      panel: [],
      dialog: false,
      updatedFrac: false,
    };
  },
  computed:{
    playerInFrac(){
      let fracs = [];
      this.fracs.forEach((frac)=>{
        fracs.push({name: frac.name, _id: frac._id, player: []});
        this.player.forEach((p) =>{
          for (let i = 0; i < frac.jobNames.length; i++) {
            if(p.Job == frac.jobNames[i]){
              fracs[fracs.length -1].player.push(p);
              break
            }            
          }
        });
      });
      return fracs
    },
  },
  methods: {
    fracCreated(frac){
      this.dialog = false;
      this.fracs.push(frac);
    },
    updateFrac(id){
      this.updatedFrac = this.fracs.find((f)=> f._id == id);
      this.dialog = true;
    },
    finishUpdateFrac(frac, id){
      this.dialog = false;
      this.updatedFrac = false;
      const index = this.fracs.findIndex((f) => f._id == id)
      this.fracs[index] = frac;
    },
    closeDialog(){
      this.dialog = false;
      this.updatedFrac = false;
    },
    deleteFrac(id){
      this.dialog = false;
      const index = this.fracs.findIndex((f) => f._id == id)
      this.fracs.splice(index, 1);
      this.updatedFrac = false;
    }
},
  async created(){
    this.loading = true;
    this.player = this.$store.getters["players/getPlayers"];
    if(this.player){
      await axios({
        method: "get",
        url: "fracs/fracs",
        headers: {
        Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        "Cache-Control": "public, max-age=300",
      },
      })
      .then((res)=>{
        this.loading = false;
        this.fracs = res.data;
      })
      .catch((err)=>{
        this.$store.dispatch("alerts/errHandler", err);
        this.loading = false;
      });
    }else{
      await axios({
        method: "get",
        url: "fracs/fracsAndPlayer",
        headers: {
        Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        "Cache-Control": "public, max-age=300",
      },
      })
      .then((res)=>{
        this.loading = false;
        this.fracs = res.data.fractions;
        this.player = res.data.player
        this.$store.commit("players/setPlayer", this.players);
      })
      .catch((err)=>{
        this.$store.dispatch("alerts/errHandler", err);
        this.loading = false;
      });
    }
  }
};
</script>
