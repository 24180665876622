<template>
  <v-card color="surface" :loading="loading">
    <v-card-title class="text-high-emphasis text-center">
      <v-row>
        <v-col cols="4"></v-col>
        <v-col cols="4" class="d-flex align-center justify-center"
          >Neuer Eintrag</v-col
        >
        <v-col cols="4" class="text-end">
          <v-btn flat icon="mdi-close" @click="$emit('close')"></v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text text-medium-emphasis>
      <v-select
        v-model="type"
        :items="['Bann', 'Punkte', 'Information']"
        label="Wähle die Art des Eintrages">
      </v-select>

      <!-- Bann -->
      <span v-if="type == 'Bann'">
        <v-form @submit.prevent="insertEntry" ref="Bann" v-model="banIsValid">
          <v-divider></v-divider>
          <br />
          <v-row>
            <v-col cols="auto" md="6">
              <v-text-field
                v-model="shortDesc"
                :loading="loading"
                :rules="[rules.notEmpty]"
                class="mb-2"
                clearable
                label="Title / Grund"></v-text-field>
            </v-col>
            <v-col cols="auto" md="6">
              <v-text-field
                type="date"
                v-model="date"
                :loading="loading"
                :rules="[rules.notEmpty]"
                :max="today"
                class="mb-2"
                clearable
                label="Datum des Verstoßes"
                hint="Wann war der Verstoß?"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto" md="6">
              <v-text-field
                type="date"
                v-model="endDate"
                :rules="[rules.notEmpty]"
                :loading="loading"
                :min="date"
                class="mb-2"
                clearable
                label="Ende des Banns (Tag)"
                hint="Bis zu welchen Tag geht der Bann?"></v-text-field>
            </v-col>
            <v-col cols="auto" md="6">
              <v-text-field
                v-model="endTime"
                :rules="[rules.notEmpty]"
                :loading="loading"
                class="mb-2"
                clearable
                type="time"
                label="Ende des Banns (Uhrzeit"
                hint="Bis zu welcher Uhrzeit geht der Bann?"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-textarea
              clearable
              :rules="[rules.notEmpty]"
              :loading="loading"
              auto-grow
              rows="1"
              label="Beschreibung"
              v-model="desc"></v-textarea>
          </v-row>
          <br />
          <v-row class="d-flex justify-center">
            <v-btn
              :loading="loading"
              color="primary"
              size="large"
              type="submit"
              variant="elevated">
              {{ buttonText }}
            </v-btn>
          </v-row>
        </v-form>
      </span>

      <!-- Add Points -->
      <span v-if="type == 'Punkte'">
        <v-form @submit.prevent="insertEntry" ref="Punkte">
          <v-divider></v-divider>
          <br />
          <v-row>
            <v-col cols="auto" md="12">
              <v-text-field
                v-model="shortDesc"
                :loading="loading"
                :rules="[rules.notEmpty]"
                class="mb-2"
                clearable
                label="Title / Grund"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto" md="6">
              <v-text-field
                type="date"
                v-model="date"
                :loading="loading"
                :rules="[rules.notEmpty]"
                :max="today"
                class="mb-2"
                clearable
                label="Datum des Verstoßes"
                hint="Wann war der Verstoß?"></v-text-field>
            </v-col>
            <v-col cols="auto" md="6">
              <v-text-field
                v-model="points"
                :rules="[rules.notEmpty, rules.notNull]"
                :loading="loading"
                class="mb-2"
                clearable
                type="number"
                label="Anzahl der Punkte"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-textarea
              clearable
              :rules="[rules.notEmpty]"
              :loading="loading"
              auto-grow
              rows="1"
              label="Beschreibung"
              v-model="desc"></v-textarea>
          </v-row>
          <br />
          <v-row class="d-flex justify-center">
            <v-btn
              :loading="loading"
              color="primary"
              size="large"
              type="submit"
              variant="elevated">
              {{ buttonText }}
            </v-btn>
          </v-row>
        </v-form>
      </span>
      <!-- Add Points -->
      <span v-if="type == 'Information'">
        <v-form @submit.prevent="insertEntry" ref="Information">
          <v-divider></v-divider>
          <br />
          <v-row>
            <v-col cols="auto" md="12">
              <v-text-field
                v-model="shortDesc"
                :loading="loading"
                :rules="[rules.notEmpty]"
                class="mb-2"
                clearable
                label="Title / Grund"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-textarea
              clearable
              :rules="[rules.notEmpty]"
              :loading="loading"
              auto-grow
              rows="1"
              label="Beschreibung"
              v-model="desc"></v-textarea>
          </v-row>
          <br />
          <v-row class="d-flex justify-center">
            <v-btn
              :loading="loading"
              color="primary"
              size="large"
              type="submit"
              variant="elevated">
              {{ buttonText }}
            </v-btn>
          </v-row>
        </v-form>
      </span>
      <br />
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  props: ["updatedEntry"],
  data() {
    return {
      loading: false,
      type: "",
      shortDesc: "",
      date: "",
      endDate: "",
      endTime: "",
      desc: "",
      points: null,
      today: new Date().toISOString().substr(0, 10),
      banIsValid: false,
      rules: {
        notEmpty: (value) => !!value || "Bitte ausfüllen.",
        notNull: (value) => value > 0 || "Die Anzahl der Punkte muss mindestens 1 sein."
      },
    };
  },
  computed: {
    buttonText() {
      if (this.updateEntry != null) {
        return "Speichern";
      } else {
        return "Eintrag erstellen";
      }
    },
  },
  methods: {
    async insertEntry() {
      const { valid } = await this.$refs[this.type].validate();
      if (!valid) return;

      this.loading = true;
      let entry;
      switch (this.type) {
        case "Bann":
          entry = {
            type: "ban",
            shortDesc: this.shortDesc,
            desc: this.desc,
            date: this.date,
            ban: { endDate: this.endDate, endTime: this.endTime },
            points: null,
            createDate: "",
            creator: "",
          };
          break;

        case "Punkte":
          entry = {
            type: "aPoints",
            shortDesc: this.shortDesc,
            desc: this.desc,
            date: this.date,
            ban: {},
            points: this.points,
            createDate: "",
            creator: "",
          };
          break;

        case "Information":
          entry = {
            type: "info",
            shortDesc: this.shortDesc,
            desc: this.desc,
            date: this.today,
            ban: {},
            points: "",
            createDate: "",
            creator: "",
          };
          break;

        default:
          break;
      }

      if (this.updatedEntry != null) {
        this.updateEntry(entry);
      } else {
        this.createEntry(entry);
      }
    },
    async createEntry(entry) {
      await axios({
        url: "/player/Entry",
        method: "post",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
        data: {
          entry,
          oocID: this.$route.params.id,
        },
      })
        .then((res) => {
          this.loading = false;
          this.$emit("close");
          console.log(res.data.player.entrys);
          this.$emit("updateEntrys", res.data.player);
          this.$store.dispatch("alerts/setAlerts", {
            type: "success",
            text: "Der Eintrag wurde erfolgreich erstellt",
          });
        })
        .catch((err) => {
          this.loading = false;
          if (err.response.status == 409) {
            this.$store.dispatch("alerts/setAlerts", {
              type: "info",
              text: "Der Spieler ist bereits gebannt, er kann also nicht nochmal gebannt werden.",
            });
          } else {
            this.$store.dispatch("alerts/errHandler", err);
          }
        });
    },
    async updateEntry(entry) {
      entry.creator = this.updatedEntry.creator;
      entry.createDate = this.updatedEntry.createDate;

      await axios({
        url: "/player/Entry",
        method: "patch",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
        data: {
          entry,
          id: this.updatedEntry._id,
          oocID: this.$route.params.id,
        },
      })
        .then((res) => {
          this.loading = false;
          this.$emit("close");
          console.log(res.data.player.entrys);
          this.$emit("updateEntrys", res.data.player);
          this.$store.dispatch("alerts/setAlerts", {
            type: "success",
            text: "Der Eintrag wurde erfolgreich bearbeitet",
          });
        })
        .catch((err) => {
          this.loading = false;

          this.$store.dispatch("alerts/errHandler", err);
        });
    },
  },
  created() {
    if (this.updatedEntry != null) {
      const entry = this.updatedEntry;

      switch (entry.type) {
        case "ban":
          this.type = "Bann";
          this.endDate = entry.ban.endDate;
          this.endTime = entry.ban.endTime;
          break;

        case "aPoints":
          this.type = "Punkte";
          this.points = entry.points;
          break;

        case "info":
          this.type = "Information";
          break;

        default:
          break;
      }

      this.shortDesc = entry.shortDesc;
      this.date = entry.date;
      this.desc = entry.desc;
    }
  },
};
</script>
