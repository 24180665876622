<template>
  <v-container style="position: relative">
    <card-bar :loading="loading" :length="players.length">
    </card-bar>
    <the-card :loading="loading">
      <v-title Class="text-h4">Spielersuche</v-title>
      <v-divider></v-divider>
      <v-text>
        <v-container style="max-width: 100%">
          <player-table :players="players"></player-table>
        </v-container>
      </v-text>
    </the-card>
  </v-container>
</template>

<script>
import axios from 'axios';
import CardBar from "../components/Layouts/CardBar.vue";
import TheCard from "../components/Layouts/TheCard.vue";
import PlayerTable from "../components/Player/PLayerSearchTable.vue"; 

export default {
  components: {
    CardBar,
    TheCard,
    PlayerTable,
  },
  data() {
    return {
      players: [],
      loading: false,
      banns: false,
    };
  },
  async created() {
    this.loading = true;
    await axios({
      method: "get",
      url: "player/init",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        "Cache-Control": "public, max-age=300",
      },
    })
      .then((res) => {
        if (res.status == 204) {
          this.$router.push("/server");
          this.$store.dispatch("alerts/setAlerts", {
            type: "info",
            text: "Bitte Konfiguriere den Server. Bei Fragen wende dich an den Support. Viel Erfolg!",
          });
          return;
        }
        this.players = res.data.players;
        this.$store.commit("players/setPlayer", this.players);
        this.loading = false;
      })
      .catch((err) => {
        this.$store.dispatch("alerts/errHandler", err);
        this.loading = false;
      });
  },
};
</script>
