<template>
  <v-expansion-panel-title>
    <v-icon
      v-if="status == false"
      icon="mdi-alert-circle"
      color="error"
    ></v-icon>
    <v-icon v-else icon="mdi-check" color="success"></v-icon>
    &nbsp;
    {{ text }}
  </v-expansion-panel-title>
</template>

<script>
export default {
  props: ["status", "text"],
};
</script>
