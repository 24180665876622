<template>
  <v-card color="surface" class="overflow-auto pb-10" :loading="loading">
    <v-card-title class="text-high-emphasis text-center">
      <v-row>
        <v-col cols="4"></v-col>
        <v-col cols="4" class="d-flex align-center justify-center">
          {{ title }}</v-col
        >
        <v-col cols="4" class="text-end">
          <v-btn flat icon="mdi-close" @click="$emit('close')"></v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-form @submit.prevent="newUser" ref="form">
      <v-row align="center" justify="center">
        <v-col cols="5">
          <v-text-field
            v-model="name"
            label="Name des Users"
            @keydown="nameError = []"
            :error-messages="nameError"
            :rules="[rules.notEmpty]"></v-text-field>
        </v-col>
        <v-col cols="5">
          <v-select
            label="Welche Rolle soll der User haben"
            clearable
            v-model="role"
            :items="roles"
            item-title="role_name"
            item-value="id"
            :rules="[rules.notEmpty]"></v-select>
        </v-col>
      </v-row>
      <span
        v-if="finishCreateUser"
        class="d-flex flex-column align-center text-medium-emphasis pb-5">
        <h3 v-if="!updateUser">Der Nutzer wurde erfolgreich angelegt</h3>
        <h3 v-else>Das Neue Passwort wurde erfolgreich erstellt</h3>
        <br />
        <h4>Link: http://localhost:8080</h4>
        <h4>Name: {{ finishedName }}</h4>
        <h4>Passwort: {{ pw }}</h4>
      </span>
      <v-row class="d-flex justify-center">
        <v-btn
          v-if="!finishCreateUser && !updateUser"
          :loading="loading"
          color="primary"
          size="large"
          type="submit">
          Nutzer anlegen
        </v-btn>
        <v-btn
          v-else-if="!finishCreateUser && updateUser"
          :loading="loading"
          color="primary"
          size="large"
          @click="editUser">
          Änderung Speichern
        </v-btn>
        <v-btn
          v-else
          :loading="loading"
          color="secondary"
          size="large"
          @click="$emit('close')">
          Schliessen
        </v-btn>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  props: ["roles", "updateUser"],
  data() {
    return {
      loading: false,
      title: "Neuen Nutzer anlegen",
      finishCreateUser: false,
      finishedName: null,
      pw: null,
      name: null,
      role: null,
      rules: {
        notEmpty: (value) => !!value || "Bitte ausfüllen.",
      },
      nameError: [],
    };
  },
  methods: {
    async newUser() {
      const { valid } = await this.$refs["form"].validate();
      if (!valid) return;

      this.loading = true;

      await axios({
        url: "user/user",
        method: "post",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
        data: {
          name: this.name,
          roleID: this.role,
        },
      })
        .then((res) => {
          this.$store.dispatch("alerts/setAlerts", {
            type: "success",
            text: "Der User wurde erfolgreich erstellt",
          });
          this.finishedName = res.data.s + "-" + this.name;
          this.pw = res.data.pw;
          this.finishCreateUser = true;
          this.$emit("newUser", this.name, this.role, res.data.id);
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.nameError.push("Der Name ist bereits vergeben");
          } else {
            this.$store.dispatch("alerts/errHandler", err);
          }
        });

      this.loading = false;
    },
    async editUser() {
      const { valid } = await this.$refs["form"].validate();
      if (!valid) return;

      this.loading = true;

      await axios({
        url: "user/user",
        method: "patch",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
        data: {
          name: this.name,
          roleID: this.role,
          id: this.updateUser.ID,
        },
      })
        .then(() => {
          this.$store.dispatch("alerts/setAlerts", {
            type: "success",
            text: "Der User wurde erfolgreich bearbeitet",
          });

          this.$emit("editUser", this.name, this.role, this.updateUser.ID);
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.nameError.push("Der Name ist bereits vergeben");
          } else {
            this.$store.dispatch("alerts/errHandler", err);
          }
        });

      this.loading = false;
    },
  },
  created() {
    if (this.updateUser) {
      this.name = this.updateUser.Name;
      this.role = this.updateUser.Role_ID;
      this.title = "Nutzer bearbeiten";
    }
  },
};
</script>
