<template>
  <v-container>
    <v-card
      class="text-center pa-2 rounded-xl"
      color="surface"
      elevation="5"
      :loading="loading"
    >
      <slot name="title"></slot>
      <slot></slot>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: ["loading"],
};
</script>

