export default{
    namespaced: true,
    state(){
        return{
            alerts: [
                // {type: "error", text: "test", id: 0, visible: true},
            ]
        }
    },
    getters: {
        getAlerts(state){
            return state.alerts.filter(alert => alert.visible == true);
        }
    },
    mutations: {
        setAlerts(state, data){
            let alerts = state.alerts;

            const alert = {
                ...data,
                visible: true,
            }
            alerts = alerts.filter(al => al.visible == true);
                
            while(alerts.length > 2){
                alerts.shift()
            }
         
            alerts.push(alert);

            state.alerts = alerts;
            return alert.id
        },
        rmvAlert(state, id){
            const index = state.alerts.findIndex((el) => el.id == id);
            state.alerts[index].visible = false;
        }
    },
    actions: {
        async setAlerts(state, data){
            data = {
                ...data,
                id: Math.random(),
            }
             await state.commit("setAlerts", data)
             if(data.type != "error"){
                setTimeout(() => {
                    state.commit('rmvAlert', data.id)
                }, 5000);
             }

        },
        setError500(state, data){
            const alert = {
                text: "Ein Fehler ist aufgetreten. Bitte melde dich mit dem Code: " + data.response.data.index +" im Support. Danke",
                type: "error",
            };
            state.dispatch("setAlerts", alert);
        },
        errHandler(state, err){
            if(err.response.status == 403){
                const alert = {
                    type: "info",
                    text: "Dir fehlen die Berechtigungen um diese Aktion durchzuführen. Bei Fragen wende dich an deinen Admin.",
                };
                state.dispatch("setAlerts", alert);
            }else if(err.response.status == 500){
                const alert = {
                    text: "Ein Fehler ist aufgetretten. Bitte melde dich mit dem Code: " + err.response.data.index +" im Support. Danke",
                    type: "error",
                };
                state.dispatch("setAlerts", alert);
            }else{
                const alert = {
                    text: "Ein unbekannter Fehler ist aufgetreten. Probiere es später erneut oder wende dich an den Support.",
                    type: "error",
                };
                state.dispatch("setAlerts", alert);
            }
        }
    }
}