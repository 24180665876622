export default {
  namespaced: true,
  state() {
    return {
      name: null,
      roleID: null,
      token: null,
      isAuth: false,
    };
  },
  getters: {
    getUsername(state) {
      return state.name;
    },
    getRoleID(state) {
      return state.roleID;
    },
    getToken(state) {
      return state.token;
    },
    isAuth(state) {
      const localName = sessionStorage.getItem("userName");
      const localToken = sessionStorage.getItem("userToken");

      
      if (localName && localToken) {
        state.name = localName;
        state.tokem = localToken;

        state.isAuth = true;
      } else {
        state.isAuth = false;
      }

      return state.isAuth;
    },
  },
  mutations: {
    setUser(state, data) {
      state.name = data.name.slice(data.name.indexOf("-") + 1);
      state.roleID = data.roleID;
      state.token = data.token;
      state.isAuth = true;

      sessionStorage.setItem("userName", state.name);
      sessionStorage.setItem("userToken", data.token);
    },
    setAuth(state){
      console.log("IS AUTH");
      state.isAuth = true;
    }
  },
};
